import React from "react"
import { graphql, StaticQuery } from "gatsby"

// Pages
import LayoutV2 from "../components/v2/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/v2.css"

// Partials
import { BlogList } from "../components/partials/base"


const InsightsPage = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title;
    const insights = data.allMarkdownRemark.edges.map((insight) =>{
        const {node} = insight;
        let item = node.frontmatter;
        item.link = node.fields.slug;
        return item;
    });

    const showList = data.markdownRemark.frontmatter.list.insights;

    return (
        <LayoutV2 location={location} title={siteTitle} headerActive={`Insights`}>
        <SEO title={`Insights`} keywords={[]} />

        <article className="post-content page-template no-image animated fadeIn">
            <div className="post-content-body">
                <div className="row">
                    <div className="col-12">
                    <h1 className="hero-title-center">Distributed Insights</h1>
                    </div>
                </div>
                <BlogList content={insights} showList={showList}/>
            </div>
        </article>
        </LayoutV2>
    )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}, limit: 1000, filter: {frontmatter: {posttype: {eq: "prosource-insight"}}}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    markdownRemark(frontmatter: {posttype: {eq: "settings"}, type: {eq: "content"}} ) {
        frontmatter {
          list{
            insights
            jobs
          }
        }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <InsightsPage location={props.location} data={data} {...props} />
    )}
  />
)
